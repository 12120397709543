@import "../../../src/styles/settings/variables";
@import "../../styles/utilities/mixins";

.Back {
  &__Container {
    padding: 3rem 0 1rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    // position: absolute;
    position: fixed;
    // width: 100vw;
    background: $primary-color;
    z-index: 999;
    // margin-top: -30px;
    @media screen and(min-width:$screen-lg) {
      //   padding: 3rem 3rem;
      padding: 3rem 0 1rem 3rem;
    }
  }

  &__Link {
    text-transform: uppercase;
    font-weight: $weight-normal;
    line-height: $line-height-t0;
    // padding: 3rem 0;
    padding: 0 6px;

    @media screen and(min-width:$screen-md) {
      padding: 0;
    }
    &:after {
      content: none;
    }
  }

  &__LinkCopy {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;

    &:hover {
      color: $quaternary-color;
    }
  }
}

.lightboxes {
  &__title {
    text-transform: uppercase;

    &:after {
      content: "";
      display: block;
      border-bottom: none;
    }
  }

  &__link {
    text-decoration: none;
    // margin-right: 1rem;

    @media screen and(min-width:$screen-md) {
      // margin-right: 1.5rem;
    }

    &:after {
      border-bottom: solid 3px $secondary-color;
    }
  }
}
