// @import "~styles/settings/variables";
// @import "~styles/utilities/mixins";

@import "../../../src/styles/settings/variables";
@import "../../styles/utilities/mixins";

.Header {
  // min-height: 10rem;
  padding-left: 0;
  padding-right: 0;
  // width: 100vw;
  width: 100%;
  background: $primary-color;

  &__userType {
    &--active {
      background: $red;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__fixingContainer {
    left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    position: fixed;
    // position: absolute;
    background: $primary-color;
    z-index: 999;
    width: inherit;
  }

  &__left {
    display: inline-flex;
    align-items: center;
    padding: 1rem;

    @media screen and(min-width:$screen-sm) {
      padding-top: 2rem;
    }
  }

  &__right {
    @media screen and(min-width:$screen-sm) {
      padding-top: 2rem;
    }

    &__menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      > li {
        margin-left: 10px;
        a {
          &:after {
            border-bottom: solid 3px $secondary-color;
          }
          h6 {
            @media screen and(max-width:$screen-sm) {
              font-size: 1.3em;
            }
          }
        }
      }
    }
  }

  &__nav {
    margin: 10px;
    @media screen and(min-width:$screen-md) {
      margin-top: 0;
    }
  }

  &__squintLogo {
    width: 75px;
    height: 60px;

    @include coverBackground;
    background-size: contain;
    transition: all 0.5s ease-out;

    &:hover {
      opacity: 0.6;
    }

    @media screen and(min-width:$screen-md) {
      width: 109px;
      height: 90px;
    }

    @media screen and(min-width:$screen-xl) {
      margin-right: 3.5rem;
    }
  }

  &__clientLogo {
    width: 100px;
    height: 52px;
    @include coverBackground;
    background-size: contain;
    transition: all 0.5s ease-out;

    &:hover {
      opacity: 0.6;
    }

    @media screen and(min-width:$screen-md) {
      width: 150px;
      height: 90px;
    }
  }

  // &__rightEmail {
  // }
}
