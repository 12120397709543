@import "../styles/settings/variables";

.getInTouch {
  width: 100%;
  a::after {
    display: none;
  }

  // margin-top: -$menuHeight;
  padding: 1em 1em 1em 1em;
  // transform: translateY(7%);
  @media screen and(min-height:$screen-sm) {
    // transform: translateY(5%);
  }
  // @media screen and(min-width:$screen-xl) {
  //   transform: translateY(10%);
  //   padding: 1em 15px 1em 30px;
  // }

  // &_center {
  //   min-height: 100vh;
  //   margin-top: 50px;
  //   @media screen and(min-width:$screen-md) {
  //     margin-top: 100px;
  //   }

  //   @media screen and(max-height:$screen-md) {
  //     margin-top: 100px;
  //   }
  //   @media screen and(max-height:$screen-md) and(min-width:$screen-xl) {
  //     margin-top: 150px;
  //   }
  // }

  &__wrap {
    // padding-bottom: 130px;
    @media screen and(min-width:$screen-xl) {
      padding-bottom: 0;
      margin-top: 0;
      max-width: 100%;
      display: flex;
      column-gap: 50px;
      margin: 0 0 0 auto;
      justify-content: space-between;
    }
    margin-top: 20px;
    > * {
      margin-bottom: 20px;
    }
  }
}

.form {
  width: 100%;
  $formFontSize: calc(min(2vh, 1.5em));
  @media screen and(min-width:$screen-xl) {
    $formFontSize: calc(min(2vh, 2em));
  }
  *:focus {
    outline-color: $red;
  }
  input {
    font-size: $formFontSize;
  }

  &__labelName {
    border: 1px solid $white;
    padding: 6px 20px;
    font-size: $formFontSize;
    text-transform: capitalize;
    font-family: $secundary-regular-font-family;
    width: 97px;

    &_message {
      text-transform: capitalize;
      font-family: $secundary-font-family;
      padding-bottom: 8px;
      font-size: $formFontSize;
    }
  }

  &__input,
  &__textarea {
    background: $black;
    border: 1px solid $white;
    color: white;
    text-indent: 10px;
  }

  &__input_group {
    margin-bottom: calc(2vh) !important;
  }

  &__textarea {
    width: 100%;

    margin-bottom: 25px;
    font-family: $secundary-regular-font-family;
    font-size: $formFontSize;
    height: 6em;
    @media screen and(min-width:$screen-xl) {
      height: 10em;
    }
  }

  &__submitBtn {
    margin-top: 30px;
    bottom: 4vh;
    font-size: 3vh;
    border: 3px solid white;
    background: $black;
    color: $white;
    cursor: pointer;
    position: relative;
    text-align: left;
    height: fit-content;
    padding-bottom: 5px;
    @media screen and(min-width:$screen-xl) {
      padding-bottom: 0px;
      margin-top: 60px;
      height: 50px;
    }

    &__text {
      $submitBtnFontSize: calc(2vh);
      font-size: $submitBtnFontSize;
      position: relative;
      line-height: $submitBtnFontSize;
      padding-left: 04px;
      padding-right: 55px;
      @media screen and(min-width:$screen-xl) {
        top: -7%;
        padding-left: 07px;
        padding-right: 55px;
      }
      text-transform: uppercase;
      font-weight: $weight-extra-bold;
      font-family: $secundary-regular-font-family;
    }

    &_icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-47%);
      right: 10px;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover > &_icon {
      // animation: linear infinite alternate;
      // animation-name: moveFromRight;
      // animation-duration: 0.25s;

      right: 30px;
    }
  }
}

.textError {
  font-size: 2em;
  color: $red;
}

.successForm {
  color: #28a745;
  font-size: 12pt;
  text-transform: uppercase;
}

.cta {
  width: 100%;
  transform: translateY(-7%);
  // @media screen and (min-width: $screen-xl) {
  //   transform: translateY(-10%);

  // }
  &__headline {
    text-transform: uppercase;
    font-size: calc(min(8vh, 15vw));
    line-height: calc(min(8vh, 15vw) + 30px);
    @media screen and (min-width: $screen-md) {
      line-height: calc(min(9vh, 15vw) + 30px);
      font-size: calc(min(9vh, 15vw));
    }
    margin-bottom: -45px;
    padding-bottom: 10px;
    white-space: nowrap;
  }
  &__subtitle {
    @media screen and (min-width: $screen-md) {
      font-size: calc(min(3.5vh, 6.56vw));
    }
    font-size: calc(min(3.3vh, 6.5625vw));
    white-space: nowrap;
  }
  &__body {
    font-size: 2.3vh;
    margin-top: 20px;
    @media screen and (min-width: $screen-xl) {
      // width: 30vw;
    }
    @media screen and (max-width: $screen-md) {
      font-size: 2vh;
    }
  }
  &__social_icon_row {
    display: flex;
    column-gap: 20px;
    @media screen and(min-width: $screen-md) {
      margin-top: 30px;
    }
    &__social_icon {
      $icon-height: calc(min(5vh, 40px));
      &__mail {
        height: $icon-height;
        width: calc(var($icon-height) * 1.5);
      }
      &__instagram {
        height: $icon-height;
        width: $icon-height;
      }
    }
  }
}
